import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const Page = ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        ogImage={
          post.frontmatter.image &&
          post.frontmatter.image.childImageSharp.gatsbyImageData.images.fallback
            .src
        }
      />
      <article className="max-w-4xl mx-4 xl:m-auto">
        <h1 className="text-4xl md:text-6xl font-bold tracking-tight my-20 text-black dark:text-white">
          {post.frontmatter.title}
        </h1>

        <section
          className="prose md:prose-lg dark:prose-light"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      </article>
    </Layout>
  )
}

export default Page
