import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PageTemplate from '../templates/page'

const UsesPage = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        markdownRemark(fileAbsolutePath: { regex: "/uses/" }) {
          id
          excerpt(pruneLength: 160)
          html
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            image {
              publicURL
            }
          }
        }
      }
    `}
    render={data => {
      return <PageTemplate data={data} />
    }}
  />
)

export default UsesPage
